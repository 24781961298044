import React from "react";
import { Avatar, Col, Modal, Row, Space, Typography } from "antd";
import data from "../../data/data";

import { useHistory, useParams } from "react-router-dom";

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

import {
  EyeFilled,
  HomeFilled,
  QuestionOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import { useToggle } from "react-use";

const CentralItemOutdoor = () => {
  function info() {
    Modal.info({
      title: "Centrales hydroélectriques de la vallée de la Romanche",
      maskClosable: true,
      closable: true,
      okText: "Fermer",
      icon: null,
      width: "50vw",
      okButtonProps: {
        type: "default",

        style: { borderColor: "#1f497c", color: "#1f497c" },
      },
      content: (
        <div>
          <Space direction="vertical">
            <Typography.Text>
              Visite virtuelle montée par{" "}
              <Typography.Text strong>
                <a href="https://mydigitalbuildings.com" target="_blank">
                  My&nbsp;Digital&nbsp;Buildings
                </a>
              </Typography.Text>{" "}
              qui avait numérisé les cinq sites en mai 2020 pour{" "}
              <Typography.Text strong>
                <a href="https://www.edf.fr/" target="_blank">
                  EDF
                </a>
              </Typography.Text>
              , la{" "}
              <Typography.Text strong>
                <a href="https://www.ccoisans.fr/" target="_blank">
                  Communauté&nbsp;de&nbsp;communes&nbsp;de&nbsp;l'Oisans
                </a>
              </Typography.Text>{" "}
              et le{" "}
              <Typography.Text strong>
                <a href="https://www.isere.fr/" target="_blank">
                  Département&nbsp;de&nbsp;l'Isère
                </a>
              </Typography.Text>
              .
            </Typography.Text>
            <Typography.Text>
              Projet rendu possible grâce à l'initiative de{" "}
              <Typography.Text strong>
                <a
                  href="https://www.ftalps.com/territoires-solutions/"
                  target="_blank"
                >
                  Territoires&nbsp;Solutions
                </a>
              </Typography.Text>{" "}
              et de la{" "}
              <Typography.Text strong>
                <a href="https://www.ftalps.com/" target="_blank">
                  French&nbsp;Tech&nbsp;in&nbsp;the&nbsp;Alps
                </a>
              </Typography.Text>
            </Typography.Text>
            <Typography.Text>
              Nicolas Gerchenzon :{" "}
              <a href="tel:+33667093431">
                &nbsp;(+33)6&nbsp;67&nbsp;09&nbsp;34&nbsp;31
              </a>
            </Typography.Text>
            <Typography.Text>
              {" "}
              <a href="mailto:contact@mydigitalbuildings.com">
                contact@mydigitalbuildings.com
              </a>
            </Typography.Text>
            {/* Logo EDF  MDB Comm Isère  French Tech */}
          </Space>

          <Row style={{ paddingTop: "10px" }} justify="center">
            <Space align="baseline" size="large">
              <img width="85" src="/logos/edf.svg" alt="" />
              <img width="85" src="/logos/mdb.svg" alt="" />
              <img width="85" src="/logos/oisans.png" alt="" />
              <img width="85" src="/logos/isere.svg" alt="" />
              <img width="85" src="/logos/frenchtech.png" alt="" />
            </Space>
          </Row>
        </div>
      ),
      onOk() {},
    });
  }

  let { centralId } = useParams();

  // const { data }: Data = APP_DATA;

  const [outdoor, toggleOutdoor] = useToggle(true);

  const history = useHistory();

  let camera;
  let controls;
  let scene;
  let renderer;
  let group;

  const onRotation = (e) => {
    // console.log(camera.position);
  };

  const resetPosition = () => {
    if (outdoor) {
      camera.position.set(
        data[centralId].initCam.x,
        data[centralId].initCam.y,
        data[centralId].initCam.z
      );
    } else {
      history.go(0);
    }
  };

  const list = [
    {
      index: 0,
      geoUrl: `/glbmodels/${centralId}/${centralId}001.glb`,
      textUrl: `/glbmodels/${centralId}/${centralId}001.jpg`,
    },
    {
      index: 1,
      geoUrl: `/glbmodels/${centralId}/${centralId}002.glb`,
      textUrl: `/glbmodels/${centralId}/${centralId}002.jpg`,
    },
    {
      index: 2,
      geoUrl: `/glbmodels/${centralId}/${centralId}003.glb`,
      textUrl: `/glbmodels/${centralId}/${centralId}003.jpg`,
    },
    {
      index: 3,
      geoUrl: `/glbmodels/${centralId}/${centralId}004.glb`,
      textUrl: `/glbmodels/${centralId}/${centralId}004.jpg`,
    },
  ];

  function animate() {
    controls.update();
    requestAnimationFrame(animate);
    renderer.render(scene, camera);
  }

  const init = () => {
    // Setup camera
    camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      1,
      10000
    );

    camera.position.set(
      data[centralId].initCam.x,
      data[centralId].initCam.y,
      data[centralId].initCam.z
    );
    camera.up.set(0, 0, 1);

    // Setup scene
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xeeeeee);

    // Setup loader
    let dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(`/vendor/`);
    group = new THREE.Group();

    // GLBs / textures loading loop
    list.forEach((m, index, array) => {
      let loader = new GLTFLoader();
      loader.setDRACOLoader(dracoLoader);
      loader.load(
        m.geoUrl,
        (function () {
          let textUrl = m.textUrl;
          return function (gltf) {
            let gltfMesh = gltf.scene.children[0];
            if (gltfMesh && gltfMesh instanceof THREE.Mesh) {
              gltfMesh.geometry.computeBoundingBox();
              const textMesh = new THREE.Mesh(
                gltfMesh.geometry,
                new THREE.MeshBasicMaterial({
                  side: THREE.DoubleSide,
                  map: new THREE.TextureLoader()
                    .setCrossOrigin("anonymous")
                    .load(textUrl, function (texture) {
                      texture.wrapS = THREE.RepeatWrapping;
                      texture.wrapT = THREE.RepeatWrapping;
                    }),
                })
              );

              textMesh.position.setZ(-50);
              if (index === array.length - 1) {
                const box = new THREE.BoxHelper(textMesh, 0xff0000);
                // scene.add(box);
                var bbox = new THREE.Box3().setFromObject(box);
                let center = bbox.getCenter(new THREE.Vector3());
                controls.target.set(center.x, center.y, center.z);
              }
              group.add(textMesh);
            }
          };
        })(),
        function (xhr) {
          // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        },
        function (error) {
          throw new Error(
            "Error while parsing gltf or glb file : " + error.message
          );
        }
      );
    });
    scene.add(group);

    // Renderer setup
    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    document.body.appendChild(renderer.domElement);
    renderer.shadowMap.enabled = true;

    // Controls setup
    controls = new OrbitControls(
      camera,
      //@ts-ignore
      document.getElementById("main-canvas-container")
    );
    controls.maxDistance = 175;
    controls.maxPolarAngle = Math.PI / 2;
    controls.enablePan = false;
    controls.update();
    controls.addEventListener("change", (e) => onRotation(e));
  };

  React.useEffect(() => {
    init();
    animate();
  }, []);

  React.useEffect(() => {
    if (!outdoor) {
    }
  }, [outdoor]);

  return (
    <>
      <Row>
        <div
          id="main-canvas-container"
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: "0",
          }}
        ></div>
      </Row>
      <Row
        justify="center"
        style={{ position: "fixed", left: "0", top: "5vh", width: "100%" }}
      >
        <Typography.Title
          style={{
            backgroundColor: "white",
            borderRadius: "50px",
            paddingLeft: "15px",
            paddingRight: "15px",
            color: "#1f497c",
          }}
        >
          {data[centralId].label} - Vue 3D
        </Typography.Title>
      </Row>
      <Row
        style={{ position: "fixed", left: "0", bottom: "5vh", width: "100%" }}
        justify="center"
      >
        <Col span={6}>
          <Space direction="vertical">
            <Avatar
              style={{ backgroundColor: "#8c8c8c" }}
              size={80}
              icon={
                <HomeFilled
                  onClick={() => {
                    toggleOutdoor(true);
                  }}
                  style={{ color: "#595959" }}
                />
              }
            />
            <Typography.Title
              level={5}
              style={{
                backgroundColor: "#8c8c8c",
                borderRadius: "50px",
                paddingLeft: "5px",
                paddingRight: "5px",
                color: "#595959",
              }}
            >
              Vue 3D
            </Typography.Title>
          </Space>
        </Col>

        <Col span={6}>
          <a href={`/viewer/${centralId}/indoor`}>
            <Space direction="vertical">
              <Avatar
                style={{ backgroundColor: "white" }}
                size={80}
                icon={
                  <EyeFilled
                    onClick={() => {
                      toggleOutdoor(false);
                    }}
                    style={{ color: "#1f497c" }}
                  />
                }
              />
              <Typography.Title
                level={5}
                style={{
                  backgroundColor: "white",
                  borderRadius: "50px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  color: "#1f497c",
                }}
              >
                Vues panoramiques
              </Typography.Title>
            </Space>
          </a>
        </Col>
      </Row>
      <Row
        style={{ position: "fixed", bottom: "5vh", right: "5vw" }}
        justify="center"
      >
        <Space direction="vertical" size="large">
          <Col>
            <Space
              onClick={() => {
                resetPosition();
              }}
              direction="vertical"
            >
              <Avatar
                style={{ backgroundColor: "white" }}
                size={50}
                icon={<SyncOutlined style={{ color: "#1f497c" }} />}
              />
              <Typography.Text
                strong
                style={{
                  backgroundColor: "white",
                  borderRadius: "50px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  color: "#1f497c",
                }}
              >
                Réinitialiser
              </Typography.Text>
            </Space>
          </Col>
          <Col>
            <Space
              onClick={() => {
                info();
              }}
              direction="vertical"
            >
              <Avatar
                style={{ backgroundColor: "white" }}
                size={50}
                icon={<QuestionOutlined style={{ color: "#1f497c" }} />}
              />
              <Typography.Text
                strong
                style={{
                  backgroundColor: "white",
                  borderRadius: "50px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  color: "#1f497c",
                }}
              >
                A propos
              </Typography.Text>
            </Space>
          </Col>
        </Space>
      </Row>
    </>
  );
};

export default CentralItemOutdoor;

// 192.168.0.184
