import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Home";
import Viewer from "../Viewer";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Routes />
      </Switch>
    </Router>
  );
};


const Routes = () => {
  return (
    <>
      <Route path="/viewer" component={Viewer} />
      <Route path="/" exact component={Home} />
    </>
  );
};

export default AppRouter;
