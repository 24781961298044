import React, { useEffect } from "react";
import { Avatar, Col, Modal, Row, Space, Typography } from "antd";
import data from "../../data/data";
import { useParams } from "react-router-dom";
import { EyeFilled, HomeFilled, QuestionOutlined } from "@ant-design/icons";
import Marzipano from "marzipano";

const CentralItemIndoor = () => {
  let { centralId } = useParams();

  const [activePano, setActivePano] = React.useState("0-01");

  const activeStyle = (item) => {
    if (item === activePano) {
      return {
        avatar: { backgroundColor: "#8c8c8c" },
        icon: { color: "#595959" },
        text: {
          backgroundColor: "#8c8c8c",
          borderRadius: "50px",
          paddingLeft: "5px",
          paddingRight: "5px",
          color: "#595959",
        },
      };
    } else {
      return {
        avatar: { backgroundColor: "white" },
        icon: { color: "#1f497c" },
        text: {
          backgroundColor: "white",
          borderRadius: "50px",
          paddingLeft: "5px",
          paddingRight: "5px",
          color: "#1f497c",
        },
      };
    }
  };

  useEffect(() => {
    var screenfull = window.screenfull;

    var panoElement = document.querySelector("#pano");
    var sceneNameElement = document.querySelector("#titleBar .sceneName");
    var sceneListElement = document.querySelector("#sceneList");
    var sceneElements = document.querySelectorAll("#sceneList .scene");
    var sceneListToggleElement = document.querySelector("#sceneListToggle");
    var autorotateToggleElement = document.querySelector("#autorotateToggle");
    var fullscreenToggleElement = document.querySelector("#fullscreenToggle");

    // Viewer options.
    var viewerOpts = {
      controls: {
        mouseViewMode: data[centralId].pano.settings.mouseViewMode,
      },
    };

    // Initialize viewer.
    var viewer = new Marzipano.Viewer(panoElement, viewerOpts);

    // Create scenes.
    var scenes = data[centralId].pano.scenes.map(function (data) {
      var urlPrefix = "/";
      var source = Marzipano.ImageUrlSource.fromString(
        urlPrefix +
          "img/tiles/" +
          centralId +
          "/" +
          data.id +
          "/{z}/{f}/{y}/{x}.jpg",
        {
          cubeMapPreviewUrl:
            urlPrefix +
            "img/tiles/" +
            centralId +
            "/" +
            data.id +
            "/preview.jpg",
        }
      );
      var geometry = new Marzipano.CubeGeometry(data.levels);

      var limiter = Marzipano.RectilinearView.limit.traditional(
        data.faceSize,
        (100 * Math.PI) / 180,
        (120 * Math.PI) / 180
      );
      var view = new Marzipano.RectilinearView(
        data.initialViewParameters,
        limiter
      );

      var scene = viewer.createScene({
        source: source,
        geometry: geometry,
        view: view,
        pinFirstLevel: true,
      });

      return {
        data: data,
        scene: scene,
        view: view,
      };
    });

    // Set up autorotate, if enabled.
    var autorotate = Marzipano.autorotate({
      yawSpeed: 0.03,
      targetPitch: 0,
      targetFov: Math.PI / 2,
    });
    if (data[centralId].pano.settings.autorotateEnabled) {
      autorotateToggleElement.classList.add("enabled");
    }

    // Set handler for autorotate toggle.
    autorotateToggleElement.addEventListener("click", toggleAutorotate);

    // Set up fullscreen mode, if supported.
    if (screenfull.enabled && data[centralId].pano.settings.fullscreenButton) {
      document.body.classList.add("fullscreen-enabled");
      fullscreenToggleElement.addEventListener("click", function () {
        screenfull.toggle();
      });
      screenfull.on("change", function () {
        if (screenfull.isFullscreen) {
          fullscreenToggleElement.classList.add("enabled");
        } else {
          fullscreenToggleElement.classList.remove("enabled");
        }
      });
    } else {
      document.body.classList.add("fullscreen-disabled");
    }

    // Set handler for scene list toggle.
    sceneListToggleElement.addEventListener("click", toggleSceneList);

    // Start with the scene list open on desktop.
    if (!document.body.classList.contains("mobile")) {
      showSceneList();
    }

    // Set handler for scene switch.
    scenes.forEach(function (scene) {
      var el = document.querySelector(
        '#sceneList .scene[data-id="' + scene.data.id + '"]'
      );
      el.addEventListener("click", function () {
        setActivePano(scene.data.id);
        switchScene(scene);
        // On mobile, hide scene list after selecting a scene.
        if (document.body.classList.contains("mobile")) {
          hideSceneList();
        }
      });
    });

    // DOM elements for view controls.
    var viewUpElement = document.querySelector("#viewUp");
    var viewDownElement = document.querySelector("#viewDown");
    var viewLeftElement = document.querySelector("#viewLeft");
    var viewRightElement = document.querySelector("#viewRight");
    var viewInElement = document.querySelector("#viewIn");
    var viewOutElement = document.querySelector("#viewOut");

    // Dynamic parameters for controls.
    var velocity = 0.7;
    var friction = 3;

    // Associate view controls with elements.
    var controls = viewer.controls();
    controls.registerMethod(
      "upElement",
      new Marzipano.ElementPressControlMethod(
        viewUpElement,
        "y",
        -velocity,
        friction
      ),
      true
    );
    controls.registerMethod(
      "downElement",
      new Marzipano.ElementPressControlMethod(
        viewDownElement,
        "y",
        velocity,
        friction
      ),
      true
    );
    controls.registerMethod(
      "leftElement",
      new Marzipano.ElementPressControlMethod(
        viewLeftElement,
        "x",
        -velocity,
        friction
      ),
      true
    );
    controls.registerMethod(
      "rightElement",
      new Marzipano.ElementPressControlMethod(
        viewRightElement,
        "x",
        velocity,
        friction
      ),
      true
    );
    controls.registerMethod(
      "inElement",
      new Marzipano.ElementPressControlMethod(
        viewInElement,
        "zoom",
        -velocity,
        friction
      ),
      true
    );
    controls.registerMethod(
      "outElement",
      new Marzipano.ElementPressControlMethod(
        viewOutElement,
        "zoom",
        velocity,
        friction
      ),
      true
    );

    function sanitize(s) {
      return s.replace("&", "&amp;").replace("<", "&lt;").replace(">", "&gt;");
    }

    function switchScene(scene) {
      stopAutorotate();
      scene.view.setParameters(scene.data.initialViewParameters);
      scene.scene.switchTo();
      startAutorotate();
      updateSceneName(scene);
      updateSceneList(scene);
    }

    function updateSceneName(scene) {
      sceneNameElement.innerHTML = sanitize(scene.data.name);
    }

    function updateSceneList(scene) {
      for (var i = 0; i < sceneElements.length; i++) {
        var el = sceneElements[i];
        if (el.getAttribute("data-id") === scene.data.id) {
          el.classList.add("current");
        } else {
          el.classList.remove("current");
        }
      }
    }

    function showSceneList() {
      sceneListElement.classList.add("enabled");
      sceneListToggleElement.classList.add("enabled");
    }

    function hideSceneList() {
      sceneListElement.classList.remove("enabled");
      sceneListToggleElement.classList.remove("enabled");
    }

    function toggleSceneList() {
      sceneListElement.classList.toggle("enabled");
      sceneListToggleElement.classList.toggle("enabled");
    }

    function startAutorotate() {
      if (!autorotateToggleElement.classList.contains("enabled")) {
        return;
      }
      viewer.startMovement(autorotate);
      viewer.setIdleMovement(3000, autorotate);
    }

    function stopAutorotate() {
      viewer.stopMovement();
      viewer.setIdleMovement(Infinity);
    }

    function toggleAutorotate() {
      if (autorotateToggleElement.classList.contains("enabled")) {
        autorotateToggleElement.classList.remove("enabled");
        stopAutorotate();
      } else {
        autorotateToggleElement.classList.add("enabled");
        startAutorotate();
      }
    }

    function createInfoHotspotElement(hotspot) {
      // Create wrapper element to hold icon and tooltip.
      var wrapper = document.createElement("div");
      wrapper.classList.add("hotspot");
      wrapper.classList.add("info-hotspot");

      // Create hotspot/tooltip header.
      var header = document.createElement("div");
      header.classList.add("info-hotspot-header");

      // Create image element.
      var iconWrapper = document.createElement("div");
      iconWrapper.classList.add("info-hotspot-icon-wrapper");
      var icon = document.createElement("img");
      icon.src = "img/info.png";
      icon.classList.add("info-hotspot-icon");
      iconWrapper.appendChild(icon);

      // Create title element.
      var titleWrapper = document.createElement("div");
      titleWrapper.classList.add("info-hotspot-title-wrapper");
      var title = document.createElement("div");
      title.classList.add("info-hotspot-title");
      title.innerHTML = hotspot.title;
      titleWrapper.appendChild(title);

      // Create close element.
      var closeWrapper = document.createElement("div");
      closeWrapper.classList.add("info-hotspot-close-wrapper");
      var closeIcon = document.createElement("img");
      closeIcon.src = "img/close.png";
      closeIcon.classList.add("info-hotspot-close-icon");
      closeWrapper.appendChild(closeIcon);

      // Construct header element.
      header.appendChild(iconWrapper);
      header.appendChild(titleWrapper);
      header.appendChild(closeWrapper);

      // Create text element.
      var text = document.createElement("div");
      text.classList.add("info-hotspot-text");
      text.innerHTML = hotspot.text;

      // Place header and text into wrapper element.
      wrapper.appendChild(header);
      wrapper.appendChild(text);

      // Create a modal for the hotspot content to appear on mobile mode.
      var modal = document.createElement("div");
      modal.innerHTML = wrapper.innerHTML;
      modal.classList.add("info-hotspot-modal");
      document.body.appendChild(modal);

      var toggle = function () {
        wrapper.classList.toggle("visible");
        modal.classList.toggle("visible");
      };

      // Show content when hotspot is clicked.
      wrapper
        .querySelector(".info-hotspot-header")
        .addEventListener("click", toggle);

      // Hide content when close icon is clicked.
      modal
        .querySelector(".info-hotspot-close-wrapper")
        .addEventListener("click", toggle);

      // Prevent touch and scroll events from reaching the parent element.
      // This prevents the view control logic from interfering with the hotspot.
      stopTouchAndScrollEventPropagation(wrapper);

      return wrapper;
    }

    // Prevent touch and scroll events from reaching the parent element.
    function stopTouchAndScrollEventPropagation(element, eventList) {
      var eventList = [
        "touchstart",
        "touchmove",
        "touchend",
        "touchcancel",
        "wheel",
        "mousewheel",
      ];
      for (var i = 0; i < eventList.length; i++) {
        element.addEventListener(eventList[i], function (event) {
          event.stopPropagation();
        });
      }
    }

    // Display the initial scene.
    switchScene(scenes[0]);
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "fixed",
          top: "0",
          left: "0",
        }}
        id="pano"
      ></div>
      {/* <div style={{ display: "none" }} id="sceneList">
        <ul className="scenes">
          <a href="javascript:void(0)" className="scene" data-id="0-01">
            <li className="text">01</li>
          </a>

          <a href="javascript:void(0)" className="scene" data-id="1-02">
            <li className="text">02</li>
          </a>

          <a href="javascript:void(0)" className="scene" data-id="2-03">
            <li className="text">03</li>
          </a>
        </ul>
      </div> */}

      <div style={{ display: "none" }} id="titleBar">
        <h1 className="sceneName"></h1>
      </div>
      <div style={{ display: "none" }}>
        <a href="javascript:void(0)" id="autorotateToggle">
          <img className="icon off" src="img/play.png" />
          <img className="icon on" src="img/pause.png" />
        </a>

        <a href="javascript:void(0)" id="fullscreenToggle">
          <img className="icon off" src="img/fullscreen.png" />
          <img className="icon on" src="img/windowed.png" />
        </a>

        <a href="javascript:void(0)" id="sceneListToggle">
          <img className="icon off" src="img/expand.png" />
          <img className="icon on" src="img/collapse.png" />
        </a>

        <a
          href="javascript:void(0)"
          id="viewUp"
          className="viewControlButton viewControlButton-1"
        >
          <img className="icon" src="img/up.png" />
        </a>
        <a
          href="javascript:void(0)"
          id="viewDown"
          className="viewControlButton viewControlButton-2"
        >
          <img className="icon" src="img/down.png" />
        </a>
        <a
          href="javascript:void(0)"
          id="viewLeft"
          className="viewControlButton viewControlButton-3"
        >
          <img className="icon" src="img/left.png" />
        </a>
        <a
          href="javascript:void(0)"
          id="viewRight"
          className="viewControlButton viewControlButton-4"
        >
          <img className="icon" src="img/right.png" />
        </a>
        <a
          href="javascript:void(0)"
          id="viewIn"
          className="viewControlButton viewControlButton-5"
        >
          <img className="icon" src="img/plus.png" />
        </a>
        <a
          href="javascript:void(0)"
          id="viewOut"
          className="viewControlButton viewControlButton-6"
        >
          <img className="icon" src="img/minus.png" />
        </a>
      </div>

      <div>
        <Row
          justify="center"
          style={{ position: "fixed", left: "0", top: "5vh", width: "100%" }}
        >
          <Typography.Title
            style={{
              backgroundColor: "white",
              borderRadius: "50px",
              paddingLeft: "15px",
              paddingRight: "15px",
              color: "#1f497c",
            }}
          >
            {data[centralId].label} - Vues panoramiques
          </Typography.Title>
        </Row>

        <Row
          style={{ position: "fixed", left: "0", bottom: "5vh", width: "100%" }}
          justify="center"
        >
          <Col span={6}>
            <a href={`/viewer/${centralId}/outdoor`}>
              <Space direction="vertical">
                <Avatar
                  style={{ backgroundColor: "white" }}
                  size={80}
                  icon={
                    <HomeFilled
                      onClick={() => {
                        // toggleOutdoor(true);
                      }}
                      style={{ color: "#1f497c" }}
                    />
                  }
                />
                <Typography.Title
                  level={5}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    color: "#1f497c",
                  }}
                >
                  Vue 3D
                </Typography.Title>
              </Space>
            </a>
          </Col>

          <Col span={6}>
            <Space>
              <Space direction="vertical">
                <Avatar
                  style={{ backgroundColor: "#8c8c8c" }}
                  size={80}
                  icon={
                    <EyeFilled
                      onClick={() => {
                        // toggleOutdoor(false);
                      }}
                      style={{ color: "#595959" }}
                    />
                  }
                />
                <Typography.Title
                  level={5}
                  style={{
                    backgroundColor: "#8c8c8c",
                    borderRadius: "50px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    color: "#595959",
                  }}
                >
                  Vues panoramiques
                </Typography.Title>
              </Space>
            </Space>
          </Col>
        </Row>
        <SceneList setActivePano={setActivePano} activeStyle={activeStyle} />

        <Help />
      </div>
    </>
  );
};

const Help = () => {
  function info() {
    Modal.info({
      title: "Centrales hydroélectriques de la vallée de la Romanche",
      maskClosable: true,
      closable: true,
      icon: null,
      okText: "Fermer",
      width: "50vw",
      okButtonProps: {
        type: "default",
        style: { borderColor: "#1f497c", color: "#1f497c" },
      },
      content: (
        <div>
          <Space direction="vertical">
            <Typography.Text>
              Visite virtuelle montée par{" "}
              <Typography.Text strong>
                <a href="https://mydigitalbuildings.com" target="_blank">
                  My&nbsp;Digital&nbsp;Buildings
                </a>
              </Typography.Text>{" "}
              qui avait numérisé les cinq sites en mai 2020 pour{" "}
              <Typography.Text strong>
                <a href="https://www.edf.fr/" target="_blank">
                  EDF
                </a>
              </Typography.Text>
              , la{" "}
              <Typography.Text strong>
                <a href="https://www.ccoisans.fr/" target="_blank">
                  Communauté&nbsp;de&nbsp;communes&nbsp;de&nbsp;l'Oisans
                </a>
              </Typography.Text>{" "}
              et le{" "}
              <Typography.Text strong>
                <a href="https://www.isere.fr/" target="_blank">
                  Département&nbsp;de&nbsp;l'Isère
                </a>
              </Typography.Text>
              .
            </Typography.Text>
            <Typography.Text>
              Projet rendu possible grâce à l'initiative de{" "}
              <Typography.Text strong>
                <a
                  href="https://www.ftalps.com/territoires-solutions/"
                  target="_blank"
                >
                  Territoires&nbsp;Solutions
                </a>
              </Typography.Text>{" "}
              et de la{" "}
              <Typography.Text strong>
                <a href="https://www.ftalps.com/" target="_blank">
                  French&nbsp;Tech&nbsp;in&nbsp;the&nbsp;Alps
                </a>
              </Typography.Text>
            </Typography.Text>
            <Typography.Text>
              Nicolas Gerchenzon :{" "}
              <a href="tel:+33667093431">
                &nbsp;(+33)6&nbsp;67&nbsp;09&nbsp;34&nbsp;31
              </a>
            </Typography.Text>
            <Typography.Text>
              {" "}
              <a href="mailto:contact@mydigitalbuildings.com">
                contact@mydigitalbuildings.com
              </a>
            </Typography.Text>
            {/* Logo EDF  MDB Comm Isère  French Tech */}
          </Space>

          <Row style={{ paddingTop: "10px" }} justify="center">
            <Space align="baseline" size="large">
              <img width="85" src="/logos/edf.svg" alt="" />
              <img width="85" src="/logos/mdb.svg" alt="" />
              <img width="85" src="/logos/oisans.png" alt="" />
              <img width="85" src="/logos/isere.svg" alt="" />
              <img width="85" src="/logos/frenchtech.png" alt="" />
            </Space>
          </Row>
        </div>
      ),
      onOk() {},
    });
  }

  return (
    <Row
      style={{ position: "fixed", bottom: "5vh", right: "5vw" }}
      justify="center"
    >
      <Space direction="vertical" size="large">
        {/* <Col>
          <Space
            onClick={() => {
              // resetPosition();
            }}
            direction="vertical"
          >
            <Avatar
              style={{ backgroundColor: "white" }}
              size={50}
              icon={<SyncOutlined style={{ color: "#1f497c" }} />}
            />
            <Typography.Text
              strong
              style={{
                backgroundColor: "white",
                borderRadius: "50px",
                paddingLeft: "5px",
                paddingRight: "5px",
                color: "#1f497c",
              }}
            >
              Réinitialiser
            </Typography.Text>
          </Space>
        </Col> */}
        <Col>
          <Space
            onClick={() => {
              info();
            }}
            direction="vertical"
          >
            <Avatar
              style={{ backgroundColor: "white" }}
              size={50}
              icon={<QuestionOutlined style={{ color: "#1f497c" }} />}
            />
            <Typography.Text
              strong
              style={{
                backgroundColor: "white",
                borderRadius: "50px",
                paddingLeft: "5px",
                paddingRight: "5px",
                color: "#1f497c",
              }}
            >
              A propos
            </Typography.Text>
          </Space>
        </Col>
      </Space>
    </Row>
  );
};

const SceneList = ({ activeStyle, setActivePano }) => {
  return (
    <Row
      style={{ position: "fixed", bottom: "5vh", right: "20vw" }}
      justify="center"
    >
      <div id="sceneList">
        <Space direction="vertical">
          <a href="javascript:void(0)" className="scene" data-id="0-01">
            <Space>
              <Avatar
                style={activeStyle("0-01").avatar}
                size={40}
                icon={<EyeFilled style={activeStyle("0-01").icon} />}
              />
              <Typography.Text style={activeStyle("0-01").text}>
                Intérieur (A)
              </Typography.Text>
            </Space>
          </a>
          <a href="javascript:void(0)" className="scene" data-id="1-02">
            <Space>
              <Avatar
                style={activeStyle("1-02").avatar}
                size={40}
                icon={<EyeFilled style={activeStyle("1-02").icon} />}
              />
              <Typography.Text style={activeStyle("1-02").text}>
                Intérieur (B)
              </Typography.Text>
            </Space>
          </a>
          <a href="javascript:void(0)" className="scene" data-id="2-03">
            <Space>
              <Avatar
                style={activeStyle("2-03").avatar}
                size={40}
                icon={<EyeFilled style={activeStyle("2-03").icon} />}
              />
              <Typography.Text style={activeStyle("2-03").text}>
                Extérieur
              </Typography.Text>
            </Space>
          </a>
        </Space>
      </div>
    </Row>
  );
};

export default CentralItemIndoor;

// 192.168.0.184
