import { Route } from "react-router-dom";
import CentralItemOutdoor from "./CentralItemOutdoor.jsx";
import CentralItemIndoor from "./CentralItemIndoor.jsx";

const CentralRoutes = (match: { path: string }) => {
  return (
    <>
      <Route
        exact
        path={`${match.path}/:centralId/outdoor`}
        component={CentralItemOutdoor}
      />
      <Route
        exact
        path={`${match.path}/:centralId/indoor`}
        component={CentralItemIndoor}
      />
    </>
  );
};

export default CentralRoutes;
