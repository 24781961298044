import { Space, Typography } from "antd";

const CentralList = (match: { url: string }) => {
  return (
    <>
      <Space direction="vertical">
        <a href={`${match.url}/ABD/outdoor`}>
          <Space direction="vertical">
            <img
              style={{
                width: 130,
                borderRadius: "10%",
                border: "solid #1f497c 2px",
              }}
              alt="centrale des clavaux"
              src="/thumbnails/ABD.png"
            />
            <Typography.Text
              strong
              style={{
                backgroundColor: "white",
                borderRadius: "50px",
                paddingLeft: "5px",
                paddingRight: "5px",
                color: "#1f497c",
              }}
            >
              Centrale&nbsp;des&nbsp;Clavaux
            </Typography.Text>
          </Space>
        </a>
        <a href={`${match.url}/ABE/outdoor`}>
          <Space direction="vertical">
            <img
              style={{
                width: 130,
                borderRadius: "10%",
                border: "solid #1f497c 2px",
              }}
              alt="centrale de riperoux"
              src="/thumbnails/ABE.png"
            />
            <Typography.Text
              strong
              style={{
                backgroundColor: "white",
                borderRadius: "50px",
                paddingLeft: "5px",
                paddingRight: "5px",
                color: "#1f497c",
              }}
            >
              Centrale&nbsp;de&nbsp;Rioupéroux
            </Typography.Text>
          </Space>
        </a>
        <a href={`${match.url}/ABF/outdoor`}>
          <Space direction="vertical">
            <img
              style={{
                width: 130,
                borderRadius: "10%",
                border: "solid #1f497c 2px",
              }}
              alt="centrale des roberts"
              src="/thumbnails/ABF.png"
            />
            <Typography.Text
              strong
              style={{
                backgroundColor: "white",
                borderRadius: "50px",
                paddingLeft: "5px",
                paddingRight: "5px",
                color: "#1f497c",
              }}
            >
              Centrale&nbsp;des&nbsp;Roberts
            </Typography.Text>
          </Space>
        </a>
        <a href={`${match.url}/ABG/outdoor`}>
          <Space direction="vertical">
            <img
              style={{
                width: 130,
                borderRadius: "10%",
                border: "solid #1f497c 2px",
              }}
              alt="centrale des vernes"
              src="/thumbnails/ABG.png"
            />
            <Typography.Text
              strong
              style={{
                backgroundColor: "white",
                borderRadius: "50px",
                paddingLeft: "5px",
                paddingRight: "5px",
                color: "#1f497c",
              }}
            >
              Centrale&nbsp;des&nbsp;Vernes
            </Typography.Text>
          </Space>
        </a>
        <a href={`${match.url}/ABH/outdoor`}>
          <Space direction="vertical">
            <img
              style={{
                width: 130,
                borderRadius: "10%",
                border: "solid #1f497c 2px",
              }}
              alt="centrale de Livet"
              src="/thumbnails/ABH.png"
            />
            <Typography.Text
              strong
              style={{
                backgroundColor: "white",
                borderRadius: "50px",
                paddingLeft: "5px",
                paddingRight: "5px",
                color: "#1f497c",
              }}
            >
              Centrale&nbsp;de&nbsp;Livet
            </Typography.Text>
          </Space>
        </a>
      </Space>
    </>
  );
};

export default CentralList;
