import CentralList from "./CentralList";
import { Switch, useRouteMatch } from "react-router-dom";
import CentralRoutes from "./CentralRoutes";
import { Col, Row, Space } from "antd";

const Viewer = () => {
  let match = useRouteMatch();

  return (
    <div>
      <Row>
        <Space>
          <Col
            span={6}
            style={{
              zIndex: 100,
              paddingLeft: "20px",
              paddingTop: "50px",
            }}
          >
            <CentralList url={match.url} />
          </Col>
          <Col span={18}>
            <Switch>
              <CentralRoutes path={match.path} />
            </Switch>
          </Col>
        </Space>
      </Row>
    </div>
  );
};

export default Viewer;
