import React from "react";
import Title from "antd/lib/typography/Title";
import { Link, Redirect } from "react-router-dom";
import { Col, Row, Space, Typography } from "antd";
import { useHistory } from "react-router-dom";

const Home = () => {

  return (
    <Redirect to='/viewer/ABG/outdoor'/>
  )
  // return (
  //   <>
  //     <Row style={{paddingTop: '10vh'}} justify="center">
  //       <Col span={12}>
  //         <div>
  //           <Space direction="vertical">
  //             <Typography.Title>
  //               Centrales hydroélectriques de la vallée de la Romanche
  //             </Typography.Title>
  //             <Typography.Text>
  //               Numérisées en mai 2020 par{" "}
  //               <Typography.Text strong>My Digital Buildings</Typography.Text>{" "}
  //               pour <Typography.Text strong>EDF</Typography.Text>, la{" "}
  //               <Typography.Text strong>
  //                 Communauté de communes de l'Oisans
  //               </Typography.Text>{" "}
  //               et le{" "}
  //               <Typography.Text strong>Département de l'Isère</Typography.Text>
  //               .
  //             </Typography.Text>
  //             <Typography.Text>Emeric Mourot : 06.45.11.29.15</Typography.Text>
  //             <Typography.Text> contact@mydigitalbuildings.com</Typography.Text>
  //             <Link to="/viewer/ABD/outdoor">
  //               <Typography.Link>Accéder aux centrales</Typography.Link>
  //             </Link>
  //           </Space>
  //         </div>
  //       </Col>
  //     </Row>
  //   </>
  // );
};

export default Home;
