import "./App.css";
import AppRouter from "./components/AppRouter";
// import { Button, Row, Typography } from "antd";
// import { SearchOutlined } from "@ant-design/icons";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div
          id="router"
          style={{
            position: "absolute",
          }}
        >
          <AppRouter />
        </div>

      </header>
    </div>
  );
}

export default App;
