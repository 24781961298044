const APP_DATA = {
  ABD: {
    label: "Centrale des Clavaux",
    initCam: {
      x: -64.83603832470999,
      y: -87.88810738925076,
      z: 32.7180451034635,
    },
    pano: {
      scenes: [
        {
          id: "0-01",
          name: "01",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: -0.6372040557806216,
            pitch: -0.07403987109116983,
            fov: 1.3378744097411812,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
        {
          id: "1-02",
          name: "02",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: -0.28699277479305074,
            pitch: 0.23574580177434612,
            fov: 1.3378744097411812,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
        {
          id: "2-03",
          name: "03",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: 0.25105890009457,
            pitch: -0.18790370923332844,
            fov: 1.3378744097411812,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
      ],
      name: "abd-clavaux",
      settings: {
        mouseViewMode: "drag",
        autorotateEnabled: false,
        fullscreenButton: false,
        viewControlButtons: false,
      },
    },
  },
  ABE: {
    label: "Centrale de Rioupéroux",
    initCam: {
      x: -28.604580487398078,
      y: -58.61564002289053,
      z: 50.65005078331218,
    },
    pano: {
      scenes: [
        {
          id: "0-01",
          name: "01",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: 0.9171944646632006,
            pitch: -0.2219662224467953,
            fov: 1.4134061960355204,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
        {
          id: "1-02",
          name: "02",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: 0.03761555931527738,
            pitch: -0.09179284584720904,
            fov: 1.4134061960355204,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
        {
          id: "2-03",
          name: "03",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: -0.8951734658572228,
            pitch: -0.20666239016788346,
            fov: 1.3940647196411804,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
      ],
      name: "abe-riouperoux",
      settings: {
        mouseViewMode: "drag",
        autorotateEnabled: false,
        fullscreenButton: false,
        viewControlButtons: false,
      },
    },
  },
  ABF: {
    label: "Centrale des Roberts",
    initCam: {
      x: -82.58874697399358,
      y: 97.05425214722838,
      z: 27.828677096641375,
    },
    pano: {
      scenes: [
        {
          id: "0-01",
          name: "01",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: -0.3718893867711035,
            pitch: -0.2959163358917074,
            fov: 1.4134061960355204,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
        {
          id: "1-02",
          name: "02",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: -1.377713349236334,
            pitch: -0.00941262840466095,
            fov: 1.4134061960355204,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
        {
          id: "2-03",
          name: "03",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: 0.475980535361181,
            pitch: -0.20030372705988952,
            fov: 1.4134061960355204,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
      ],
      name: "abf-roberts",
      settings: {
        mouseViewMode: "drag",
        autorotateEnabled: false,
        fullscreenButton: false,
        viewControlButtons: false,
      },
    },
  },
  ABG: {
    label: "Centrale des Vernes",
    initCam: {
      x: -51.25441674227423,
      y: 33.838872279023924,
      z: 37.278507458222656,
    },
    pano: {
      scenes: [
        {
          id: "0-01",
          name: "01",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: 0.06665163671768504,
            pitch: -0.037841409231823775,
            fov: 1.4134061960355204,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
        {
          id: "1-02",
          name: "02",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: 0.47992482064243625,
            pitch: 0.2574296298947214,
            fov: 1.4134061960355204,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
        {
          id: "2-03",
          name: "03",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: 1.0337076905444338,
            pitch: -0.43453828961037644,
            fov: 1.4134061960355204,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
      ],
      name: "abg-vernes",
      settings: {
        mouseViewMode: "drag",
        autorotateEnabled: false,
        fullscreenButton: false,
        viewControlButtons: false,
      },
    },
  },
  ABH: {
    label: "Centrale de Livet",
    initCam: {
      x: -27.005178334219423,
      y: 49.97685569637848,
      z: 17.277831707886964,
    },
    pano: {
      scenes: [
        {
          id: "0-01",
          name: "01",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: 1.456393814625983,
            pitch: 0.53998400956886,
            fov: 1.4134061960355204,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
        {
          id: "1-02",
          name: "02",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: 0.3920278012171856,
            pitch: -0.01470383558944377,
            fov: 1.4134061960355204,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
        {
          id: "2-03",
          name: "03",
          levels: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
            {
              tileSize: 512,
              size: 2048,
            },
          ],
          faceSize: 2048,
          initialViewParameters: {
            yaw: -0.5061148045900534,
            pitch: -0.19154932899490085,
            fov: 1.4134061960355204,
          },
          linkHotspots: [],
          infoHotspots: [],
        },
      ],
      name: "abh-livet",
      settings: {
        mouseViewMode: "drag",
        autorotateEnabled: false,
        fullscreenButton: false,
        viewControlButtons: false,
      },
    },
  },
};


export default APP_DATA;
